
import { mapState } from 'vuex';

export default {
  name: 'Photo',
  computed: {
    ...mapState('inspections__new', [
      'getInspectionPhotosResult',
    ]),
  },
  watch: {
    '$route.params': {
      handler(newVal) {
        this.$store.commit('inspections__new/photos/reset');
        const photoFromList = this.getInspectionPhotosResult?.find(x => x.id === newVal.photo_id);
        if (photoFromList) {
          this.$store.state.inspections__new.currentPhoto = photoFromList;
        } else if (this.$store.state.inspections__new.getPhotosItemResult &&
          this.$store.state.inspections__new.getPhotosItemResult.id === newVal.photo_id) {
            this.$store.state.inspections__new.currentPhoto = this.$store.state.inspections__new.getPhotosItemResult;
        } else {
          this.$store.dispatch('inspections__new/getPhotosItem', {
            id: newVal.photo_id,
            onSuccess: newData => this.$store.state.inspections__new.currentPhoto = newData,
          });
        }
      },
      deep: true,
      immediate: true,
    }
  },
}
